var image = 1
var bgImageArray = []
var secs

function toggleMenu () {
  $('.mobile-button').toggle()
  $('.mobile-nav-menu').toggle()
  $('.mobile-close').toggle()
}

function initSlider () {
  bgImageArray = [1, 2, 3],
  secs = 4
  bgImageArray.forEach(function (img) {
    new Image().src = './images/home/Slide' + image + '_image.jpg'
    // caches images, avoiding white flash between background replacements
  })

  backgroundSequence()
}

function backgroundSequence () {
  window.clearTimeout()
  var k = 1
  for (var i = 0; i < bgImageArray.length; i++) {
    setTimeout(function () {
      var mq = window.matchMedia('screen and (max-device-width: 1245px)')
      // only screen and (device-width : 375px) and (device-height : 812px) and (-webkit-device-pixel-ratio : 3)';
      if (mq.matches) {
        document.getElementById('HomeBanner').style.background = 'url(./images/home/htdr-dogs-' + [k] + '.png' + ') no-repeat center center fixed'
        document.getElementById('HomeBanner').style.backgroundSize = 'contain'
        document.getElementById('HomeBanner').style.backgroundPosition = 'center top'
      } else {
        document.getElementById('HomeBanner').style.background = 'url(./images/home/Slide' + [k] + '_image.jpg' + ') no-repeat center center fixed'
        document.getElementById('HomeBanner').style.backgroundSize = 'cover'
      }
      // document.getElementById('HomeBanner').style.backgroundSize = 'cover'
      if (k === bgImageArray.length) {
        setTimeout(function () {
          backgroundSequence()
        }, (secs * 1000))
      } else {
        k++
      }
    }, (secs * 1000) * i)
  }
}

function createEmail () {
  var url = siteSettings.Api.Url

  var name = $('#Name').val()
  var email = $('#Email').val()
  var phone = $('#Phone').val()
  var message = $('#Message').val()

  var object = {
    Name: name,
	  Email: email,
	  Phone: phone,
    Message: message
  }

  $.ajax({
    url: url + '/Contact/ContactForm/',
    data: { model: object },
    type: 'POST',
    success: function (data) {
      if (data.success) {
        debugger;
        console.log("Success");
        $('#SubmitButton').attr('disabled', true)
        $('#ContactFormWrapper').empty().html('Thank you for contacting us!').css({ 'font-size': '28px', 'margin-top': '20px' })
        $('.contact-form-error').hide()
      } else {
        console.log("failure" + data);
      }
    },
    error: function (e) {
      console.log("error: " + e);
    }
  })
}
